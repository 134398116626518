import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  FC,
} from 'react';

import { message } from 'antd';

import {
  SimpleUserDocument,
  SimpleUserQuery,
} from '@codegen/generated/graphql';
import { getApolloClient } from '@lib/apollo';
import { auth } from '@lib/firebase';
import { ERROR_MESSAGE } from '@utils/constants';
import { logoutAction } from '@utils/helpers';
import { Platform } from '@utils/types';

import { INITIAL_VALUES } from './constants';
import { AuthProps } from './types';

export const UserContext = createContext<AuthProps>(INITIAL_VALUES);

const FirebaseUserProvider: FC<{ platform: Platform }> = ({
  children,
  platform,
}) => {
  const [user, setUser] = useState<SimpleUserQuery['me'] | null>(null);
  const [userLoading, setUserLoading] = useState(true);
  const [isSigningUp, setIsSigningUp] = useState<boolean | null>(false);

  const handleSigninError = useCallback(async () => {
    message.error(ERROR_MESSAGE.SIGN_IN_FAILED);
    setUser(null);
    setUserLoading(false);
    await auth.signOut();
    logoutAction({ platform });
  }, [platform]);

  const onAuthStateChange = useCallback(
    () =>
      auth.onAuthStateChanged(async (firebaseUser) => {
        if (!firebaseUser) {
          setUserLoading(false);
          setUser(null);

          return;
        }
        setUserLoading(true);
        try {
          if (isSigningUp) return;
          const client = getApolloClient({ platform });
          const { data } = await client.query<SimpleUserQuery>({
            fetchPolicy: 'network-only',
            query: SimpleUserDocument,
          });
          if (!data?.me) handleSigninError();
          else {
            setUser(data?.me);
            setUserLoading(false);
          }
        } catch (err) {
          handleSigninError();
        }
      }),
    [isSigningUp, platform, handleSigninError],
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChange();

    return () => {
      unsubscribe();
    };
  }, [onAuthStateChange]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userLoading,
        isSigningUp,
        setIsSigningUp,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export default FirebaseUserProvider;
