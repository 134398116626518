import styled from 'styled-components';
import theme from 'theme';

const { breakpoints, color, margin } = theme;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: ${color.white};
  z-index: 9999;
  display: none;
  @${breakpoints.mobile} {
    display: block;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-rows: 100px 1fr;
  height: 100%;
`;

export const RectangleLeft = styled.img.attrs({
  src: '/images/mobile/left-rectangle.svg',
})`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 382px;
`;

export const RectangleRight = styled.img.attrs({
  src: '/images/mobile/right-rectangle.svg',
})`
  position: absolute;
  right: 0;
  top: 0;
  height: 382px;
`;

export const Logo = styled.img.attrs({
  src: '/images/logo-dark.svg',
})`
  margin: auto;
  height: 40px;
`;

export const MobileNotAvailable = styled.img.attrs({
  src: '/images/mobile/mobile-not-available.svg',
})`
  width: 88px;
  height: 88px;
`;

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
`;

export const AppLinks = styled.div`
  margin-top: ${margin.xl};
  img {
    height: 45px;
  }
  a:not(:last-child) {
    margin-right: ${margin.sm};
  }
`;
