import React, { useContext, FC } from 'react';

import { Spin } from 'antd';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { ROUTES, PRIVATE_ROUTES, ACCOUNT_ROUTES } from '@utils/pages';

import Redirect from './redirect';
import { UserContext } from './userContext';

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const PageLoader = (
  <Wrap>
    <Spin />
  </Wrap>
);

const CustomRouter: FC<{ extraParams: string }> = ({
  children,
  extraParams,
}) => {
  const { user, userLoading } = useContext(UserContext);
  const { route: routeLink } = useRouter();

  const privateRoutes = PRIVATE_ROUTES.map((route) => route.route);
  const accountRoutes = ACCOUNT_ROUTES.map((route) => route.route);
  const route = routeLink.replace(/\[(.+?)\]/g, ':$1');
  if (userLoading) {
    return PageLoader;
  } else if (!user && privateRoutes.includes(route)) {
    return <Redirect to={ROUTES.SIGN_IN.route + extraParams} />;
  } else if (user && route === ROUTES.LANDING_PAGE.route) {
    return <Redirect to={ROUTES.MENU.route + extraParams} />;
  } else if (user && accountRoutes.includes(route)) {
    return <Redirect to={ROUTES.MENU.route + extraParams} />;
  }

  return <>{children}</>;
};
export default CustomRouter;
